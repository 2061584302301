import cx from 'clsx'

const LayoutBlock = ({ layout, children }) => {
  if (!layout) return children

  const classNames = cx({
    ['mt-' + layout.marginTop]: layout.marginTop,
    ['mb-' + layout.marginBottom]: layout.marginBottom
  })

  if (!classNames.length) return children

  return <div className={classNames}>{children}</div>
}

export default LayoutBlock
