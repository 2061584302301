import Blocks from '@/containers/blocks'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export async function getStaticProps(context) {
  const res = await fetch(`${publicRuntimeConfig.api}/fixed-pages/homepage`)
  const props = await res.json()
  return { props, revalidate: parseInt(process.env.REVALIDATE_PAGE) }
}

export default Blocks
