import * as blocks from '@/blocks'
import LayoutBlock from '@/components/common/layout-block'

const Example = props => (
  <div
    style={{
      backgroundColor: '#eee',
      padding: 10
    }}
  >
    <div
      style={{
        backgroundColor: 'white',
        color: '#669',
        padding: 20
      }}
    >
      <h5>[WIP] {props.__component.split('.').pop()}</h5>
      <pre>{JSON.stringify(props, null, 2)}</pre>
    </div>
  </div>
)

export default function Blocks(props) {
  if (!props.blocks) return null

  return (
    <>
      {props.blocks.map((block, i) => {
        const component = block.__component
        const { __component, layout, ...props } = block

        const name = __component
          .split('.')
          .pop()
          .split('-')
          .map((s, i) => (i ? s[0].toUpperCase() + s.slice(1) : s))
          .join('')

        let Block = blocks[name] || Example

        return (
          <LayoutBlock key={i} layout={layout}>
            <Block key={i} {...block} />
          </LayoutBlock>
        )
      })}
    </>
  )
}
