import NextHead from 'next/head'
import stringMapper from '@/lib/string-mapper'

const defaultTitle = 'The Official McLaren Store'

const Head = props => {
  const map = stringMapper(props)
  const title = map(props.title || defaultTitle)
  const description = map(props.description || '')

  return (
    <NextHead>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {props.children}
    </NextHead>
  )
}

export default Head
