const commonValues = {
  siteName: 'Mclaren'
}

const stringMapper = props => {
  const values = {
    ...props,
    ...commonValues
  }

  return str => {
    try {
      return new Function(...Object.keys(values), `return \`${str}\``)(
        ...Object.values(values)
      )
    } catch (err) {
      return values.site
    }
  }
}

export default stringMapper
