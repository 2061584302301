import Head from '@/components/global/head'
import Blocks from '@/components/blocks'

export default function BlocksPage(props) {
  return (
    <>
      <Head {...props} />
      <Blocks blocks={props.blocks} />
    </>
  )
}
